import React, { useState } from 'react';
import './App.css'; // Make sure to import your CSS file

const Scroll = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleWidgetClick = (index) => {
    setActiveIndex(index);
    scrollToSection(index);
    resetActiveWidgetAfterDelay();
  };

  const resetActiveWidgetAfterDelay = () => {
    setTimeout(() => {
      setActiveIndex(-1); // Reset active index to deactivate the widget
    }, 5000); // Set the delay in milliseconds (e.g., 5000ms = 5 seconds)
  };

  const scrollToSection = (index) => {
    let sectionId = '';
    if (index === 0) {
      sectionId = 'main-section';
    } else if (index === 1) {
      sectionId = 'about-section';
    } else if (index === 2) {
      sectionId = 'contact-section';
    }

    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      if (index === 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className='scroll-indicator'>
      {[0, 1, 2].map((_, index) => (
        <div
          key={index}
          className={`scroll-widget ${activeIndex === index ? 'active' : ''}`}
          onClick={() => handleWidgetClick(index)}
        >
          <div className={`widget-content ${activeIndex === index ? 'active' : ''}`}>
            {index === 0 && (
              <>
                {activeIndex === index ? (
                  <>
                    <div className='home-active-container'>
                    <img src={process.env.PUBLIC_URL + '/images/home.png'} alt="home" className="home-active--primary" />
                    <img src={process.env.PUBLIC_URL + '/images/home-active-primary.png'} alt="home" className="home-secondary" />
                    </div>
                    <span className="text">Home</span>
                  </>
                ) : (
                    <div className='home-container'>
                        <img src={process.env.PUBLIC_URL + '/images/home-primary.png'} alt="Contact Us Icon" className="home-primary" />
                        <img src={process.env.PUBLIC_URL + '/images/home-secondary.png'} alt="Contact Us Icon" className="home-secondary" />
                    </div>
                )}
              </>
            )}

            {index === 1 && (
              <>
                {activeIndex === index ? (
                  <>
                    <img src={process.env.PUBLIC_URL + '/images/about.png'} alt="about" className="home-active-container" />
                    <span className="text">About Us</span>
                  </>
                ) : (
                    <img src={process.env.PUBLIC_URL + '/images/about-active.png'} alt="about" className="home-container" />
                )}
              </>
            )}

            {index === 2 && (
              <>
                {activeIndex === index ? (
                  <>
                    <img src={process.env.PUBLIC_URL + '/images/contact.png'} alt="Contact" className="home-active-container" />
                    <span className="text">Contact Us</span>
                  </>
                ) : (
                    <img src={process.env.PUBLIC_URL + '/images/contact-active.png'} alt="Contact" className="home-container" />
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Scroll;
