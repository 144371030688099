import React from "react";

function About() {
    return (
        <section className="about-section">
            <div className="about-section-content">
                <h2 className="about-section-header">
                Connecting People,<br /> Creating Opportunities
                </h2>
                <p>
                    Say hello to Shoppeet, your go-to destination for buying and selling, 
                    brought to you by the trailblazers at Yerhma Co-Creation Hub Limited. 
                    Our mission? Simple yet profound - to bring together students and the wider community through a seamless and empowering buying and selling experience. 
                    With a dedicated team fueled by passion and innovation, 
                    Shoppeet is poised to redefine the very essence of shopping.
                </p>
            </div>

            <div className="image-container1">
                <img src={process.env.PUBLIC_URL + '/images/iphone-screen.png'} alt="iphone" className="App-section-image overlay0"/>
                <img src={process.env.PUBLIC_URL + '/images/iphone-frame.png'} alt="iphone" className="App-section-image overlay"/>
            </div>
            <div className="about-section-content2">
                <h2 className="about-section-header2">
                The Journey's Beginning
                </h2>
                <p>
                Shoppeet's origin story is as inspiring as it gets. 
                Picture this: a world where online transactions are safe, 
                seamless, and scam-free. Fueled by this vision, 
                Shoppeet was conceived to provide a secure haven for transactions, 
                all while fostering trust and community. 
                Emerging from the dynamic roots of Yerhma Co-Creation Hub Limited, 
                Shoppeet took shape to bridge the gap and create a hub where buyers and sellers thrive.
                </p>
            </div>
            <div className="image-row">
                <div className="image-row-container">
                    <img src={process.env.PUBLIC_URL + '/images/image1.png'} alt="iphone" className="image"/>
                    <img src={process.env.PUBLIC_URL + '/images/iphone-frame.png'} alt="iphone" className="overlay2"/>                    
                </div>
                <div className="image-row-container">
                    <img src={process.env.PUBLIC_URL + '/images/image2.png'} alt="iphone" className="image"/>
                    <img src={process.env.PUBLIC_URL + '/images/iphone-frame.png'} alt="iphone" className="overlay2"/>   
                </div>
                <div className="image-row-container">
                    <img src={process.env.PUBLIC_URL + '/images/image3.png'} alt="iphone" className="image"/>
                    <img src={process.env.PUBLIC_URL + '/images/iphone-frame.png'} alt="iphone" className="overlay2"/>   
                </div>
            </div>
            <div className="about-section">
            <div className="about-section-content">
                <h2 className="about-section-header">
                The Yerhma Connection
                </h2>
                <p>
                Shoppeet proudly stands under the Yerhma Co-Creatiom Hub Limited umbrella, 
                a powerhouse dedicated to igniting change through innovation. 
                Sharing a heritage of community empowerment, 
                Shoppeet is rooted in Yerhma's legacy of collaboration and progress. 
                This connection is the heartbeat behind Shoppeet's mission to build a vibrant ecosystem for all things shopping.
                </p>
                <div className="image-row2">
                    <img src={process.env.PUBLIC_URL + '/images/screen-1.png'} alt="iphone" className="App-section-image2"/>
                    <img src={process.env.PUBLIC_URL + '/images/screen2.png'} alt="iphone" className="App-section-image3"/>
                </div>
            </div>
        </div>
        </section>
    );
}

export default About;