import './App.css';
import About from './about';
import Contact from './contact';
import Scroll from './scroll';

function App() {
  const openGoogleForm = () => {
    window.open('https://forms.gle/ENGAv7Jv1Q47aujv7', '_blank');
  };
  return (
    <div className="App">
      <header className="App-header">
        <h1>Your Ultimate Buying and <br /> Selling App</h1>
      </header>
      <main className="App-main" id="main-section">
        <div className="App-section-content">
          <p>Shoppeet is here to transform your shopping experience! 
           Say goodbye to the ordinary and hello to a whole new world of possibilities, 
           tailor-made for students and the wider community.
          </p>
         </div>
         <div className="image-container">
            <img src={process.env.PUBLIC_URL + '/images/iphone-frame.png'} alt="iphone" className="App-section-image"/>
            <img src={process.env.PUBLIC_URL + '/images/Shoppeet-logo-2.png'} alt="iphone" className="App-section-image overlay1"/>
          </div>
        <button className="App-section-button" onClick={openGoogleForm}>Join the waitlist</button>
      </main>
      <div id="about-section">
        <About/>
      </div>
      <div id="contact-section">
        <Contact />
      </div>
      <Scroll />
    </div>
  );
}

export default App;
