import React from "react";

function Contact() {
    const openInstagram = () => {
        window.open('https://instagram.com/shoppeet_ng?igshid=OGQ5ZDc2ODk2ZA==', '_blank')
    }
    const openFacebook = () => {
        window.open('https://www.facebook.com/shoppeet.ng/photos/?locale=it_IT', '_blank')
    }
    return (
        <section className="contactSection">
            <div className="contact-section-container">
                <p className="get-in-touch">Get in Touch:</p>
                <p className="get-in-touch-text">
                    Questions? Ideas? We'd love to hear from you!
                </p>
                <p className="email">Email:<a href="help@shoppeet.com" className="tag"> help@shoppeet.com</a></p>
                <p className="lets-stay-connected-text">
                    Lets stay connected:
                </p>
                <p className="social">Facebook: <a href="#" onClick={openFacebook} className="tag">@shoppeet</a></p>
                <p className="social">Instagram: <a href="#" onClick={openInstagram} className="tag">@shoppeet</a></p>
                <div className="contact-text">
                    <p className="contact-text-header">
                        Step into a shopping realm like no other
                    </p>
                    <p className="contact-text-body">
                        it all starts with Shoppeet, where possibilities are endless.
                        Join our waitlist today and be part of the new era of shopping,
                        where convenience and community blend seamlessly.
                        Remember, it's all about Shoppeet - Your Ultimate Buying and Selling App!
                    </p>
                </div>
                <div class="button-group">
                    <div class="button">
                        <img src={process.env.PUBLIC_URL + '/images/playstore.png'} alt="googe-play-store" />
                    </div>
                    <div class="button">
                        <img src={process.env.PUBLIC_URL + '/images/appstore.png'} alt="apple-app-store" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;